.modal-90w {
    width: 90%;
}



.modal-lg {
    max-width: 1800px;
    width: 80%;    
}

.logo {
    width: auto;
    height: 80px;   
}

.copy {
    text-align: center;
}

.close:hover {
    background-color: black;
    color: white;
    cursor: pointer;
}

.modal {
    margin-top: 80px;
    z-index: 1300;
  }
  
  .modal .logo {
    width: auto;
    height: 100px;
  }
  .modal .logo-tti {
    width: auto;
    height: 50px;
    margin-top: 10px;
  }
  .modal .title {
    text-align: center;
  }