html,
body {
  margin: 0;
  font-size: 100%;
}

html {
  /* background: url(https://ttitamu.github.io/airport-eic/assets/images/page-background.jpg)
    no-repeat center center fixed; */

  background: url(https://ttitamu.github.io/airport-eic/assets/images/clouds-bg.png)
    no-repeat center center fixed;

  background-color: gainsboro;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

body {
  background: inherit;
}

#container {
  display: flex;
  /* displays flex-items (children) inline */
  flex-direction: column;
  /* stacks them vertically */
  height: 100%;
  /* needs to take the parents height, alternative: body {display: flex} */
}

main {
  flex: 1;
  /* takes the remaining height of the "container" div */
  margin: 0;
}

.space-between {
  justify-content: space-between;
}
.space-evenly {
  justify-content: space-evenly;
}
.space-around {
  justify-content: space-around;
}

.App {
  /* text-align: center; */
  font-size: 1rem;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  color: white;
  min-height: 150px;
  font-size: 12px;
  position: fixed;
  width: 100%;
  z-index: 1300;
}
.header-background {
  background-color: #282c34;
  background: url(/assets/images/header-bg-sasser-foo.jpg) no-repeat center
    fixed;
  background-size: cover;
  background-position: center;
  /*
  background-image: url(/assets/images/header-bg-crop-1.jpg);
  background-repeat: no-repeat;
  background-position: center left;
  **/
}

.opacity-half {
  opacity: 0.5;
}
.opacity-full {
  opacity: 1;
}
.header-left,
.nav-left {
  background: #333;
  height: 170px;
  min-height: 170px;
  width: 45%;
}

.nav-buttons {
  justify-content: flex-end;
  width: 100%;
}

.nav-left a {
  color: white;
  margin-left: 10px;
}
.navbar,
.header-right {
  min-height: 170px;
  height: 170px;
  position: relative;
  width: 55%;
  flex-direction: row-reverse;
  justify-content: flex-end;
}
.navbar,
.navbar a,
.header-right,
.header-right a {
  color: white;
}
header .btn {
  color: white;
  border: 1px solid white;
  margin-right: 10px;
  z-index: 1;
}
header .btn:hover {
  background: blue;
  color: white;
}

.navbar img,
.header-right img {
  position: absolute;
  left: 0;
  overflow: hidden;
  height: 170px;
  width: 100%;
  z-index: 0;
}

.App-link {
  color: #61dafb;
}
.site-title {
  font-size: 45px;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
}
.site-title:hover {
  text-decoration: unset;
}

header .county,
header .region {
  color: white;
}

.section-nav-row {
  color: white;
  justify-content: space-evenly;
}

nav li {
  color: white;
}

.btn-nav {
  background-color: #751851;
  border-color: #513;
  color: white;
}
.btn-nav:hover,
.btn-nav:focus,
.btn-nav.selected {
  background-color: #402;
}

.airport-input-wrap {
  color: #222;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.airport-input {
  max-width: 195px;
}

textarea.debug {
  width: 100%;
  min-height: 400px;
}

.fixed {
  position: fixed;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row,
.screen-flex-row {
  display: flex;
  flex-direction: row;
}

.align-items-center {
  align-items: center;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-space-around {
  justify-content: space-around;
}

nav ul {
  list-style: none;
  justify-content: space-evenly;
  width: 50%;
}

.map-wrap {
  background: white;
  height: auto;
  min-width: 150px;
}
.leaflet-container {
  height: auto;
  background: white;
}

.table-wrap {
  margin: 1rem;
}

table,
.table {
  background: white;
  margin-bottom: 0;
}

.table td,
.table th {
  padding: 0.1rem;
  padding-right: 10px;
  /* text-align: right; */
  text-align: center;
}

.table th,
.table .thead-themed th,
.table-bordered th {
  background-color: #751851;
  border-color: #513;
  color: white;
}

th.label,
th.item,
td.label,
td.item {
  /* wide enough for "Group Passenger Transport" */
  min-width: 10rem;
}

.summary th.label,
.summary th.item,
.summary td.label,
.summary td.item {
  /* wide enough for "Group Passenger Transport" */
  max-width: 14rem;
}

th.employment {
  max-width: 4rem;
}
th.factor-input,
th.factor {
  max-width: 5rem;
}
td.factor-input,
td.factor {
  max-width: 5rem;
  padding: 0;
  padding-right: 2px;
  text-align: right;
  vertical-align: middle;
}
td.employment {
  max-width: 4rem;
  padding: 0;
  padding-right: 2px;
  text-align: right;
  vertical-align: middle;
}

th.labor-income,
td.labor-income {
  max-width: 8rem; /* so Labor Income doesn't wrap **/
  text-align: right;
  vertical-align: middle;
}
th.output,
td.output {
  /* needs to fit values like: $183,343,363 */
  max-width: 8rem;
  text-align: right;
  vertical-align: middle;
}

/* SECTIONS */

.section-full {
  width: 100%;
}
.section-big {
  width: 65.8%;
}
.section-small {
  width: 32%;
}
.section-25pct {
  width: 25%;
}

.section-33pct {
  width: 33%;
}

section {
  border-radius: 4px;
  /*   border: 1px solid yellow; */
}

section.sponsored tr:last-child {
  border-top: 3px solid #cec7d0;
}
section.activity {
  display: none;
}

section.show {
  display: block;
}

.section-summary {
  /* Keep an eye on widths of: */
  /* Airport Capital Improvements */
  /* Labor Income */
  min-width: 450px;
}
.section-big .total-row td,
.section-summary table tbody .grand-total-row td {
  background: #bbb;
  color: #222;
  font-weight: 600;
}
.section-summary table tbody .grand-total-row td {
  background: white;
  border-top: 3px solid #cec7d0;
}

table input {
  text-align: right;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.black-bg-30pct {
  background-color: rgba(0, 0, 0, 0.3);
}

.black-bg-50pct {
  background-color: rgba(0, 0, 0, 0.5);
}

.white-bg-80pct {
  background-color: rgba(255, 255, 255, 0.8);
}

.panel {
  border-radius: 4px;
  width: 100%;
}

.pad0 {
  padding: 0;
}
.pad0-lr {
  padding-left: 0;
  padding-right: 0;
}

.pad1 {
  padding: 0.3rem;
}

.pad2 {
  padding: 1rem;
}

.margin0 {
  margin: 0;
}

.margin1 {
  margin: 0.3rem;
}

.margin2 {
  margin: 1rem;
}

section .title,
section h1,
section h2 {
  /* background: rgba(0, 0, 0, 0.2); */
  color: rgba(255, 255, 255, 0.9);
  color: #751851;
}

section h1 {
  font-size: 20px;
  text-align: center;
}

section h2 {
  font-size: 14px;
  text-align: left;
}

table td input[type="text"] {
  /* max-width: 5rem; */
  width: 100%;
}

.tenant-group {
}

.display-none {
  display: none;
}
.debug {
  /* display: none; */
  resize: both;
}

.summary {
  color: white;
  right: 5px;
}
.top-row {
  justify-content: space-between;
  vertical-align: top;
  margin-top: 180px;
  width: 100%;
  height: 350px;
}

.white-bg {
  background-color: rgba(255, 255, 255, 1);
}

.pad-lr {
  padding-left: 1.5%;
  padding-right: 1.5%;
}

.chart {
  max-height: 420px;
}
.cursor-pointer {
  cursor: pointer;
}

.break-before-recto {
  break-before: recto;
}
.break-after-recto {
  break-after: recto;
}

/* MOBILE */
@media (max-width: 800px) {
  section,
  .section-full,
  .section-big,
  .section-small,
  .section-25pct,
  .section-33pct {
    width: 100%;
    min-width: 100%;
  }
  header {
    width: 100%;
  }
  .App-header,
  .nav-left,
  .header-right {
    min-height: 40px;
    height: 40px;
  }
  .header-right {
    width: 0;
  }
  .header-right img {
    dispaly: none;
    width: 0;
  }

  .nav-left {
    justify-content: space-around;
    width: 100%;
  }
  .site-title {
    font-size: 15px;
    width: 75%;
  }

  .top-row {
    margin-top: 40px;
    flex-direction: column !important;
  }
  .mobile-flex-column {
    flex-direction: column !important;
  }
  .mobile-flex-row {
    flex-direction: row !important;
  }
}
/* PRINT */
@media print {
  html {
    background: white; /* save ink */
  }
  input[type="text"] {
    border: 0;
    margin: 0;
    padding: 0;
  }
  .noprint {
    display: none;
  }
  .App-header {
    position: static;
  }
  .btn-print {
    display: none;
  }
  section.visitor {
    break-after: recto;
  }
  section.summary {
    break-before: recto;
  }

  section.tenant {
    display: block;
    break-after: recto;
    break-before: recto;
  }
  section.activity {
    width: 100%;
    display: block;
  }
  .tenant-group-air-ambulance {
    break-before: recto;
  }
  .tenant-group-other-professional-services {
    break-before: recto;
  }

  .flex-column {
    display: block;
  }
  main {
    display: block;
    margin-top: 0;
  }
  .top-row {
    margin-top: 0;
  }
  .print-flex-row {
    display: flex;
    flex-direction: row;
  }
  .print-flex-column {
    display: flex;
    flex-direction: column;
  }
  .print-block {
    display: block;
  }
}
